import { extractGroupName } from '@arcadehq/shared/helpers/groups'
import type { TeamPrefs } from '@arcadehq/shared/types'
import type { Timestamp } from 'firebase/firestore'
import type { AuthUser } from 'next-firebase-auth'
import { Flow, Team, UserProfile } from 'src/types'

import { DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS } from '../../constants'

export type Plan =
  | 'Free'
  | 'Free (Growth Churned)'
  | 'Free (Growth Trial Ended)'
  | 'Free (Pro Churned)'
  | 'Free (Pro Churned) (Growth Churned)'
  | 'Free (Pro Churned) (Growth Trial Ended)'
  | 'Pro'
  | 'Pro (Growth Churned)'
  | 'Pro (Growth Trial Ended)'
  | 'Growth'
  | 'Growth Trial'

export type AccountProtectedFields = {
  authUser: AuthUser
  userProfile: UserProfile
  team: Team | null
  plan: Plan
}

export type AccountMixin<T> = (
  core: AccountCore,
  fields: AccountProtectedFields,
  ...args: any
) => T

export type TrialProgress =
  | {
      trialActive: true
      daysTotal: number
      daysUsed: number
      daysLeft: number
      upgradeRequested: boolean
    }
  | {
      trialActive: false
      teamExists: boolean
    }

const normalizeDate = (date: string | Date | Timestamp) => {
  if (typeof date === 'object' && 'seconds' in date) {
    return new Date(date.seconds * 1000)
  }
  return new Date(date)
}

export class AccountCore {
  protected plan: Plan

  constructor(
    protected authUser: AuthUser,
    protected userProfile: UserProfile,
    protected team: Team | null
  ) {
    // The `undefined` state of `userProfile.currentSubscriber` means that the user has never been Pro.
    // For teams, we don't have that distinction, `currentSubscriber` is either `trueish` or `falseish`.

    if (userProfile.currentSubscriber) {
      this.plan = 'Pro'
    } else if (
      userProfile.currentSubscriber === false &&
      userProfile.customerId
    ) {
      this.plan = 'Free (Pro Churned)'
    } else {
      this.plan = 'Free'
    }

    if (team && userProfile.isActiveMemberOfTeam) {
      if (team.currentSubscriber && team.customerId) {
        this.plan = 'Growth'
      } else if (team.currentSubscriber && !team.customerId) {
        this.plan = 'Growth Trial'
      } else if (!team.currentSubscriber && team.customerId) {
        this.plan += ' (Growth Churned)'
      } else if (!team.currentSubscriber && !team.customerId) {
        this.plan += ' (Growth Trial Ended)'
      }
    }
  }

  get isLoggedIn(): boolean {
    return !!this.authUser.id
  }

  logOut(): Promise<void> {
    return this.authUser.signOut()
  }

  get userId(): string {
    return this.authUser.id ?? ''
  }

  get userEmail(): string | null {
    return this.authUser.email
  }

  get userEmailGroup(): string | null {
    return extractGroupName(this.userEmail)
  }

  get userProfileGroup(): string | null {
    return this.userProfile.group ?? null
  }

  get userEmailVerified(): boolean {
    return this.authUser.emailVerified
  }

  get userName(): string | null {
    return this.authUser.displayName
  }

  get userFirstName(): string | null {
    return this.authUser.displayName?.split(' ')[0] || null
  }

  get userPhotoUrl(): string | null {
    return this.userProfile.photoURL ?? null
  }

  getUserIdToken(forceRefresh: boolean = false): Promise<string | null> {
    return this.authUser.getIdToken(forceRefresh)
  }

  async fetchWithToken(
    method: 'GET' | 'POST',
    apiPath: string,
    requestBody?: any
  ): Promise<Response> {
    const idToken = await this.getUserIdToken()
    return fetch(apiPath, {
      method,
      headers: {
        Authorization: idToken!,
        'Content-Type': 'application/json',
      },
      ...(requestBody
        ? {
            body: JSON.stringify(requestBody),
          }
        : {}),
    })
  }

  get firebaseUser(): AuthUser['firebaseUser'] {
    return this.authUser.firebaseUser
  }

  get isClientInitialized(): boolean {
    return this.authUser.clientInitialized
  }

  // TODO account for deprovisioned state
  get teamIsTrialing(): boolean {
    return this.plan === 'Growth Trial'
  }

  get teamIsDeprovisioned(): boolean {
    return !!this.team && !this.team.currentSubscriber && !this.team.customerId
  }

  // TODO fold this into something else
  get previouslyHadProSubscription(): boolean {
    return !!this.userProfile.customerId && !this.userProfile.currentSubscriber
  }

  // TODO fold this into something else
  get hasProSubscription(): boolean {
    return !!this.userProfile.customerId && !!this.userProfile.currentSubscriber
  }

  // TODO fold this into something else
  get hasNeverHadProSubscription(): boolean {
    return !this.userProfile.customerId && !this.userProfile.currentSubscriber
  }

  // TODO break this out into entitlements
  get isActiveMemberOfTeam(): boolean {
    return !!this.userProfile.isActiveMemberOfTeam
  }

  // TODO reconcile the following two methods
  get isExternalMemberOfTeam(): boolean {
    return !!this.userProfile.isExternalMemberOfTeam
  }

  get isExternalUser(): boolean {
    return this.userEmailGroup === this.userEmail
  }

  // TODO determine whether this is ever different from team.id
  get teamGroup(): string | null {
    return this.team?.group ?? null
  }

  // TODO break out a .trial feature?
  get teamTrialProgress(): TrialProgress {
    if (!this.team || !this.teamIsTrialing) {
      return {
        trialActive: false,
        teamExists: !!this.team,
      }
    }

    const dayMs = 1000 * 60 * 60 * 24

    const teamCreatedMs = normalizeDate(this.team.created).getTime()
    const nowMs = Date.now()

    const daysSinceCreated = (nowMs - teamCreatedMs) / dayMs

    let trialLengthDays = DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS
    if (this.team.trialEndDateIso) {
      const trialEndDateMs = new Date(this.team.trialEndDateIso).getTime()
      trialLengthDays = (trialEndDateMs - teamCreatedMs) / dayMs
    }

    const trialDaysRemaining = Math.max(
      0,
      Math.ceil(trialLengthDays - daysSinceCreated)
    )

    // They're paying for pro, and the trial is expired, so they're not in a trial.
    if (trialDaysRemaining === 0 && this.hasProSubscription) {
      return {
        trialActive: false,
        teamExists: true,
      }
    }

    return {
      trialActive: true,
      daysTotal: trialLengthDays,
      daysUsed: daysSinceCreated,
      daysLeft: trialDaysRemaining,
      upgradeRequested: !!this.team.trialUpgradeRequested,
    }
  }

  get mayEditVideo(): boolean {
    return this.plan === 'Growth' || this.plan === 'Growth Trial'
  }

  get mayInviteCollaborators(): boolean {
    return this.plan === 'Growth' || this.plan === 'Growth Trial'
  }

  mayEditFlow(flow: Flow): boolean {
    return (
      (!!this.userId &&
        (flow.createdBy === this.userId ||
          flow.editors?.includes(this.userId))) ||
      (!this.team?.features['Edit Access to Arcades is Controlled'] &&
        flow.belongsToTeam &&
        this.isActiveMemberOfTeam &&
        this.teamGroup === flow.group)
    )
  }

  mayToggleFlowBelongsToTeam(flow: Flow): boolean {
    return this.isActiveMemberOfTeam && flow.createdBy === this.userId
  }

  mayDeleteFlow(flow: Flow): boolean {
    return this.userId === flow.createdBy
  }

  get mayUseURLDestinationInOverlay(): boolean {
    return !this.plan.startsWith('Free')
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get mayEditAppearanceSettings(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO move to .privacy
  get mayEditAccessControl(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO move to .privacy
  get mayEditPrivacySettings(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get hasAppearanceSettings(): boolean {
    return !!this.team?.prefs
  }

  // TODO remove the reset button?
  // Dumb hack of firebase
  resetAppearanceSettings(deleteFieldValue: any): Promise<boolean> {
    if (!this.team) return Promise.resolve(false)

    return this.team.update(
      { prefs: deleteFieldValue as TeamPrefs },
      this.userId
    )
  }

  // TODO move to .trial
  get didDismissTrialBanner(): boolean {
    return this.userProfile?.dismissedGrowthTrialBanner ?? false
  }

  // TODO move to .trial
  dismissTrialBanner(): Promise<boolean> {
    if (!this.userProfile) return Promise.resolve(false)

    return this.userProfile.update(
      { dismissedGrowthTrialBanner: true },
      this.userId
    )
  }

  get mayViewTeamSettings(): boolean {
    return this.plan.startsWith('Growth')
  }

  get mayViewUserSettings(): boolean {
    return this.plan.startsWith('Free') || this.plan.startsWith('Pro')
  }

  get planName(): 'Free' | 'Pro' | 'Growth' {
    return this.plan.startsWith('Growth')
      ? 'Growth'
      : this.plan.startsWith('Pro')
      ? 'Pro'
      : 'Free'
  }

  // Note: This currently disregards whether or not the user / team is a
  // current subscriber. It also ignores an overdue flag if the user is
  // trialing growth.
  get isOverdue(): boolean {
    return (
      !!(this.userProfile.isOverdue || this.team?.isOverdue) &&
      !this.teamIsTrialing
    )
  }
}
